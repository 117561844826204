import classNames from 'classnames'

import Icon from '@/components/Icon/Icon'
import { addCard } from '@/store/clipboard/actions/addCard'
import { REMOVE_CARD } from '@/store/clipboard/actions/types'
import { selectClipboardIncludesCard } from '@/store/clipboard/selectors'
import { useActionless, useAppSelector } from '@/store/hooks'
import { CardType } from '@/types/card'

type Props = {
  card: CardType
  className: string
  classNameInner: string
}

const ClipboardButton = ({ card, className, classNameInner }: Props) => {
  const [removeCard] = useActionless(REMOVE_CARD)

  const clipboardIncludesCard = useAppSelector((state) => selectClipboardIncludesCard(card)(state))

  const handleClick = () => {
    if (!clipboardIncludesCard) addCard(card)
    else if (clipboardIncludesCard) removeCard(card)
  }

  return (
    <div className={classNames(className, { 'd-flex': clipboardIncludesCard })} onClick={handleClick}>
      <div className={classNameInner}>
        <Icon
          className='d-flex text-white edhrec-clipboard-dont-close'
          icon={clipboardIncludesCard ? 'check' : 'plus'}
        />
      </div>
    </div>
  )
}

export default ClipboardButton
