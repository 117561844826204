import querystring from 'querystring'

import { NextRouter } from 'next/router'
import { v4 } from 'uuid'

import * as Filters from '@/store/filters/actions/types'
import { store } from '@/store/store'
import { PageCardType } from '@/types/card'
import { AddFilterPayloadType, CountFilterRowType, NameFilterRowType, SetFormPayloadType } from '@/types/filters'

const addFilter = ({ filter, type }: AddFilterPayloadType) => {
  store.dispatch({
    payload: { filter, type },
    type: Filters.ADD_FILTER,
  })
}

const getNameFilterPreposition = ({
  cardName,
  nameFilters,
}: {
  cardName: string
  nameFilters: NameFilterRowType[]
}): string | null => nameFilters?.find(({ name }) => name[0] === cardName)?.preposition || null

const initializeForm = (routerPath: string) => {
  const encoded = querystring.parse(routerPath.split('?')[1]).f as string
  const f = decodeURIComponent(encoded)

  const countForm = f
    ?.split(';')
    .map((str) => {
      const splitStr = str.split('=')
      const splitKey = splitStr[0].split(':')
      return splitStr.length === 2 && splitKey.length === 2
        ? {
            key: v4(),
            number: parseInt(splitStr[1]),
            op: splitKey[1],
            type: splitKey[0],
          }
        : {}
    })
    .filter(
      (row) => ['gt', 'lt', 'eq', 'ne'].includes(row.op as string) && (row.type || '').length > 0 && row.number,
    ) as CountFilterRowType[]

  const nameForm = f
    ?.split(';')
    .map((str) => {
      const splitStr = str.split('=')
      return splitStr.length === 2 ? { key: v4(), name: [splitStr[1]], preposition: splitStr[0] } : {}
    })
    .filter((row) => ['in', 'out'].includes(row.preposition as string) && row.name) as NameFilterRowType[]

  const payload = {} as { countForm?: CountFilterRowType[]; nameForm?: NameFilterRowType[] }
  if (countForm.length > 0) payload.countForm = countForm
  if (nameForm.length > 0) payload.nameForm = nameForm

  store.dispatch({ payload, type: Filters.INIT_FILTER_FORM })
}

const removeFilter = ({ key, type }: { key: string; type: AddFilterPayloadType['type'] }) => {
  store.dispatch({
    payload: { key, type },
    type: Filters.REMOVE_FILTER,
  })
}

const reset = () =>
  store.dispatch({
    type: Filters.RESET,
  })

const setError = (error: string) => {
  store.dispatch({
    payload: error,
    type: Filters.SET_ERROR,
  })
}

const setForm = ({ filter, type }: SetFormPayloadType) => {
  store.dispatch({
    payload: { filter, type },
    type: Filters.SET_FILTER_FORM,
  })
}

const submit = ({ card, dir, router }: { card: PageCardType; dir: string; router: NextRouter }) => {
  store.dispatch({
    payload: '',
    type: Filters.SET_ERROR,
  })

  const { countFilters, nameFilters } = store.getState().filters

  if (nameFilters && nameFilters.length > 8) {
    filtersService.setError('Error: too many name filters (max 8)')
    return
  }

  const countQueries = countFilters
    ?.filter(({ number, type }) => number && type?.length)
    .map(({ number, op, type }) => `${type}:${op}=${number}`)
    .join(';')

  const nameQueries = nameFilters
    ?.filter(({ name }) => name)
    .map(({ name, preposition }) => `${preposition}=${name}`)
    .join(';')

  const urlencoded = encodeURIComponent(`${nameQueries}${nameQueries && countQueries && ';'}${countQueries}`)

  const subpage =
    card?.cards && (card.cards[0].legal_partner || card.cards[0].background_chooser || card.cards[1].doctors_companion)
      ? card.cards.map(({ url }) => url).join('+')
      : card.sanitized_wo

  const query = urlencoded ? { f: urlencoded } : {}

  router.push({ pathname: `/${dir}/${subpage}`, query }, undefined, {
    scroll: false,
  })
}

const filtersService = {
  addFilter,
  getNameFilterPreposition,
  initializeForm,
  removeFilter,
  reset,
  setError,
  setForm,
  submit,
}

export default filtersService
