import { ForwardRefRenderFunction, forwardRef } from 'react'

type Props = {
  children: React.ReactNode
  className: string
  style: React.CSSProperties
}

const CustomMenuRenderFunction: ForwardRefRenderFunction<HTMLDivElement, Props> = (props, ref) => {
  const { children, className, style } = props
  return (
    <div className={className} ref={ref} style={style}>
      {children}
    </div>
  )
}

const CustomMenu = forwardRef(CustomMenuRenderFunction)
export default CustomMenu
