import classNames from 'classnames'
import { ForwardRefRenderFunction, forwardRef } from 'react'

type Props = {
  children: React.ReactNode
  className: string
  classNameInner: string
  onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

const CustomToggleRenderFunction: ForwardRefRenderFunction<HTMLAnchorElement, Props> = (props, ref) => {
  const { children, className, classNameInner, onClick } = props
  return (
    <a
      className={classNames(className, 'edhrec-clipboard-dont-close')}
      href=''
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
      ref={ref}
    >
      <div className={classNames(classNameInner, 'edhrec-clipboard-dont-close')}>{children}</div>
    </a>
  )
}

const CustomToggle = forwardRef(CustomToggleRenderFunction)
export default CustomToggle
