import Spinner from '@/components/Spinner/Spinner'

import styles from './CardLoading.module.scss'

const CardLoading = () => (
  <div className={styles.spinnerContainer}>
    <Spinner className={styles.spinner} />
  </div>
)

export default CardLoading
