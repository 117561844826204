import salt_48 from '@/assets/salt_48.png'
import { useAppSelector } from '@/store/hooks'

import styles from './CardSalt.module.scss'

type Props = {
  salt: number
}

const CardSalt = ({ salt }: Props) => {
  const firebaseSaltLevel = useAppSelector((state) => state.user.saltLevel)
  const saltThreshold = firebaseSaltLevel === 0 ? 9999 : firebaseSaltLevel === 2 ? 0 : 2
  return (
    salt > saltThreshold && (
      <div className={styles.salty}>
        <img alt='Salt Score' src={salt_48.src} />
        <div className={styles.saltyTooltip}>
          <img alt='Salt Score' src={salt_48.src} />
          <div>Salt Score: {salt.toFixed(2)}</div>
        </div>
      </div>
    )
  )
}

export default CardSalt
